import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { getShortTimezoneText } from 'helpers/getTimeZoneText';

dayjs.extend(utc);

export default async function craftSMSMesage(datareceived) {
    try {
        const { appttocreate } = datareceived;
        const { apptToConfirm, isMulti } = appttocreate;
        const {
            dibsStudioId,
            originalStartTimeUnFormatted,
            mainTimezone,
            studioName,
            customerServicePhone,
            timeOfFirstAppt,
        } = apptToConfirm;
        const formattedDate = dayjs
            .utc(originalStartTimeUnFormatted)
            .format('M/D/YY');
        const formattedTime = isMulti
            ? dayjs.utc(timeOfFirstAppt).format('h:mm a')
            : dayjs.utc(originalStartTimeUnFormatted).format('h:mm a');
        const shortTimeZone = getShortTimezoneText(mainTimezone);
        let signature;
        let addresstoadd;

        if (dibsStudioId === '226') {
            signature = '-BARBER PISTERZI';
            addresstoadd =
                'As a reminder, the address is: 367 W Broadway, New York, NY 10012';
        }
        if (dibsStudioId === '260') {
            signature = '-BARBER PISTERZI';
        }
        // format the data and start time
        const message = `Your appointment at ${studioName} is confirmed.  We look forward to seeing you on ${formattedDate} at ${formattedTime} ${shortTimeZone}.  If you have any questions, please call us at ${customerServicePhone}.  Thank you!  ${signature}\n\n${addresstoadd}`;

        return message;
    } catch (err) {
        console.log('error in notifyViaTwilio is', err);
        return 0;
    }
}
