import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Paper, Button, Typography, Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

import {
    addToRecentsSearch,
    addOrUpdateSearchResults,
} from 'store/slices/clientsearch';
import { getNewSearchResults } from 'actions/studios/getNewSearchResults';

import { useSelector, useDispatch } from 'store';

export default function ComboBox({
    title,
    onChangeAction,
    setIsAddingNewClient,
    clientSelected,
    idtoadd,
    setClientSelected,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { results } = useSelector((state) => state.clientsearch);
    const { recents } = results;
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const [searchTerm, setSearchTerm] = React.useState('');
    const [countLastSearchTerm, setCountLastSearchTerm] = React.useState(0);
    const [countSearchResults, setCountSearchResults] = React.useState(100);
    const [searchResults, setSearchResults] = React.useState(recents);

    const [searchedUserid, setSearchedUserid] = React.useState(5);

    React.useEffect(() => {
        getNewSearchResults(config.dibsStudioId, searchTerm).then((result) => {
            if (result !== 0) {
                dispatch(addOrUpdateSearchResults(result));
                setSearchResults(result);
                setCountSearchResults(result?.length);
            }
        });

        return () => {
            setSearchedUserid(5);
        };
    }, [
        config.dibsStudioId,
        searchTerm,
        dispatch,
        countSearchResults,
        searchedUserid,
        dibsStudioId,
    ]);
    const filterOptions = createFilterOptions({
        stringify: ({ label, email, id, phone }) =>
            `${label} ${email} ${id} ${phone}`,
    });
    const renderSuggestion = (suggestion) => (
        <div key={suggestion.key}>
            <span style={{ fontWeight: '450' }}>{suggestion.label}</span>
            <div>
                <span style={{ fontWeight: '300', fontStyle: 'italic' }}>
                    {suggestion.email}
                </span>
            </div>
            <div>
                <span style={{ fontWeight: '300' }}>
                    {suggestion.phonelabel}
                </span>
            </div>
        </div>
    );
    const PaperComponentCustom = (options) => {
        const { children } = options;

        return (
            <Paper sx={{ bgcolor: '#fff' }}>
                {children}
                <Button
                    id={`add-client-button-search-${idtoadd}`}
                    fullWidth
                    color="primary"
                    // className={classes.buttonBackground}
                    // startIcon={props.addButtonIcon}
                    onMouseDown={(event) => {
                        event.preventDefault();
                    }}
                    onClick={() => {
                        setIsAddingNewClient(true);
                    }}
                    sx={{
                        paddingTop: '10px',
                        paddingBottom: '13px',
                        paddingLeft: '15px',
                        backgroundColor: '#fff',
                        color: '#e7b2a5',
                        justifyContent: 'flex-start',
                        '&:hover': {
                            backgroundColor: '#ffffff',
                        },
                        alignItems: 'center',
                    }}
                >
                    <AddIcon color="#e7b2a5" fontSize="small" />
                    <Typography
                        variant="caption"
                        sx={{
                            ml: 0.5,
                            fontSize: '12px',
                            fontWeight: '500',
                        }}
                    >
                        {'Create A New Client'}
                    </Typography>
                </Button>
            </Paper>
        );
    };
    const setRecentOptions = (event, value) => {
        if (value) {
            dispatch(addToRecentsSearch(value));
            setSearchTerm(value?.label);
            setSearchedUserid(value?.id);
            onChangeAction(value, 'fromAutoComplete');
        }
    };
    const testResetOfSearch = (valuefromfield) => {
        const lengthValue = valuefromfield?.length;
        if (lengthValue === 0) {
            setClientSelected(null);
        }
        if (
            valuefromfield?.length < countLastSearchTerm ||
            countLastSearchTerm === 0
        ) {
            setCountSearchResults(100);
            if (valuefromfield?.length > 1) {
                setCountLastSearchTerm(valuefromfield?.length);
                dispatch(addOrUpdateSearchResults([]));
            }
        }
    };
    return (
        <Fade in={true} timeout={1000}>
            <Autocomplete
                disablePortal
                autoComplete
                PaperComponent={PaperComponentCustom}
                id={`autocomplete-box-clientsearch`}
                options={searchResults}
                filterOptions={filterOptions}
                size="small"
                name="clientsearchaddappt"
                clearOnEscape
                renderOption={(props, option) => {
                    const htmlForList = renderSuggestion(option);
                    return (
                        <li {...props} key={option.id}>
                            {htmlForList}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField {...params} label={title} />
                )}
                isOptionEqualToValue={(option, value) =>
                    option.key === value.key
                }
                filterSelectedOptions
                onChange={(event, value) => setRecentOptions(event, value)}
                onInputChange={(event, value) => {
                    setSearchTerm(value);
                    testResetOfSearch(value);
                    return value;
                }}
            />
        </Fade>
    );
}
