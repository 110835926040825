import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function sendConfirmationEmail(datareceived) {
    const url = getApiUrl('/send-appt-email-confirmation');
    let valuetoreturn;
    await axios
        .post(url, { data: datareceived })
        .then((res) => {
            const { data } = res;

            valuetoreturn = data;
        })
        .catch((error) => {
            console.log(
                'there was an error - sending appt confirmaton email',
                error
            );
        });
    return valuetoreturn;
}
