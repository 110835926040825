import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import setAlert from 'actions/status/setAlert';

import AffectedClients from 'components/schedule-sections/affectedClients';
import sendCancelSmss from 'actions/twilio/sendCancelSmss';
import sendCancelEmails from 'actions/emails/send-cancellation-emails';

// const attendees = [
//     {
//         firstname: 'John',
//         lastname: 'Doe',
//         email: 'alison@gmail.com',
//         mobilephone: '123-456-7890',
//         userid: 12222,
//         key: 12222,
//         checked: true,
//         sendEmail: true,
//     },
//     {
//         firstname: 'Jane',
//         lastname: 'Doe',
//         email: 'jane@gmail.com',
//         mobilephone: '123-456-7890',
//         userid: 12223,
//         key: 12223,
//         checked: true,
//         sendEmail: true,
//     },
// ];

export default function DeleteClassDialog({
    isopen,
    setNotifyClientsModalOpen,
    attendees,
    dibsId,
    eventType,
}) {
    // const [open, setOpen] = React.useState(isopen);
    const [attendeeList, setAttendeeList] = React.useState(attendees);
    const [numEmailsToSend, setNumEmailsToSend] = React.useState(0);
    const [numSmsToSend, setNumSmsToSend] = React.useState(0);
    const [buttonText, setButtonText] = React.useState('Send Notifications');

    React.useEffect(() => {
        if (attendees) setAttendeeList(attendees);
    }, [attendees]);
    const handleClose = () => {
        // setOpen(false);
        setNotifyClientsModalOpen(false);
    };
    const handleSendCancelEmail = async () => {
        setNotifyClientsModalOpen(false);

        if (numEmailsToSend > 0 || numSmsToSend > 0) {
            let errorSMS = false;
            let errorEmail = false;
            if (numSmsToSend > 0) {
                await sendCancelSmss(attendeeList).catch((err) => {
                    console.log(
                        'error in sending sms cancelations - delete class dialog',
                        err
                    );
                    errorSMS = true;
                });
            }
            if (numEmailsToSend > 0) {
                await sendCancelEmails(attendeeList)
                    .then((res) => {
                        if (!res.emailsent) {
                            console.log('error in sending cancelations emails');
                            errorEmail = true;
                        }
                    })
                    .catch((err) => {
                        console.log('error in sending email cancelations', err);
                        errorEmail = true;
                    });
            }
            if (errorSMS || errorEmail) {
                let errormsg = 'There was an error sending ';
                let errorplus = '';
                if (errorSMS) errorplus += 'SMS ';
                if (errorEmail) errorplus += 'email ';
                if (errorSMS && errorEmail) errorplus = 'SMS and email ';
                const fullmsg = `${errormsg}${errorplus}notifications. Our development team has been notified. In the meantime, you should notify the client manually.`;
                setAlert({
                    msg: fullmsg,
                    seconds: 15,
                    error: true,
                });
            } else {
                setAlert({
                    msg: 'Notifications sent!',
                    seconds: 5,
                    success: true,
                });
            }
        } else {
            console.log('not sending cancel email');
        }
    };
    React.useEffect(() => {
        let numEmails = 0;
        let numSms = 0;
        if (attendeeList) {
            attendeeList.forEach((attendee) => {
                if (attendee.shouldSendEmail) numEmails++;
                if (attendee.shouldSendSms) numSms++;
            });
            setNumEmailsToSend(numEmails);
            setNumSmsToSend(numSms);
        }
        setNumEmailsToSend(numEmails);
        setNumSmsToSend(numSms);
    }, [attendeeList]);
    React.useEffect(() => {
        if (numEmailsToSend === 0 && numSmsToSend === 0) setButtonText('Exit');
        else setButtonText('Send Notifications');
    }, [numEmailsToSend, numSmsToSend]);

    return (
        <React.Fragment>
            <Dialog
                open={isopen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Send Cancellation Notifications?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The following clients were enrolled in this appointment.
                        Would you like to send them an email and/or sms
                        notifying them of the cancellation?
                    </DialogContentText>
                    <AffectedClients
                        attendees={attendeeList}
                        thiseventid={122222}
                        dibsId={dibsId}
                        eventType={eventType}
                        setAttendeeList={setAttendeeList}
                        isUpdating={false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="cancel">
                        Do Not Send Notifications
                    </Button>
                    <Button onClick={handleSendCancelEmail} autoFocus>
                        {buttonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
