import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function createNewAppt(apptToConfirm, multiAppts) {
    const url = getApiUrl('/appts/create-new-appt');
    let returndata;
    try {
        const allAppts = {
            apptToConfirm,
            multiAppts,
        };
        const response = await axios.post(url, allAppts);

        const { data } = response;

        returndata = data;
    } catch (err) {
        console.error('Error creating new appt:', err);
        return null;
    }
    return returndata;
}
