import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { Grid } from '@mui/material';

import dayjs from 'dayjs';

import ChangesDialog from './whatchanged';

// MANUAL VARIABLES - Update this date to show the notification until

const showUntil = new Date('2024-10-24');
const changes = [
    `Bugfix - Resolved start/end time issue when trying to create 2 appointments. (10/16/24)`,
    `Bugfix - Resolved issue that caused error when trying to update appointments. (10/16/24)`,
    // `You can edit the client's email, phone number and/or birthday by double clicking on any of the fields.`,
    // `You now have the option to press 'Enter' or 'Save' to submit changes.`,
];

// in the future, pass changes into the notification so that you can make changes dynamic specific to the page you are on

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: '#666',
        color: '#fff',
        // borderRadius: '8px',
        // boxShadow: '0px 3px 5px rgba(0,0,0,0.3)',
        // padding: theme.spacing(1),
    },
    '& .MuiSnackbarContent-message': {
        fontSize: '1rem',
    },
}));

function UpdateNotification() {
    console.log('this is second notification');
    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const today = dayjs();
    const showUntilDate = dayjs(showUntil);
    const isBeforeShowUntil = today.isBefore(showUntilDate);

    useEffect(() => {
        const hasSeenNotification = localStorage.getItem(
            'hasSeenUpdateNotification'
        );
        const hasSeenSecondNotification = localStorage.getItem(
            'hasSeenSecondNotification'
        );

        if (!hasSeenNotification && isBeforeShowUntil) {
            setOpen(true);
        }
        if (!hasSeenSecondNotification && !isBeforeShowUntil) {
            setOpen(true);
        }
    }, [isBeforeShowUntil]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleRefresh = () => {
        localStorage.setItem('hasSeenUpdateNotification', 'true');
        localStorage.setItem('hasSeenSecondNotification', 'true');
        setDialogOpen(false);
        window.location.reload();
    };
    const handleDialogOpen = () => {
        setDialogOpen(true);
        setOpen(false);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Grid container>
                <CustomSnackbar
                    open={open}
                    // message="There have been updates to this page. Please refresh your screen."
                    message={
                        <>
                            <Grid item xs={12}>
                                There have been updates to this page. Please
                                refresh your screen.
                            </Grid>
                            <Grid item xs={12}>
                                <Link
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDialogOpen();
                                    }}
                                    sx={{
                                        color: '#e7b2a5',
                                        fontSize: '0.9rem',
                                    }}
                                >
                                    What changed?
                                </Link>
                            </Grid>
                        </>
                    }
                    action={
                        <>
                            <Button
                                // color="primary"
                                variant="edit"
                                size="small"
                                onClick={handleRefresh}
                                // sx={{
                                //    //  backgroundColor: '#ff9800',
                                //     color: '#fff',
                                //     '&:hover': {
                                //         backgroundColor: '#f57c00',
                                //     },
                                // }}
                            >
                                Refresh
                            </Button>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    autoHideDuration={200000}
                />
                <ChangesDialog
                    open={dialogOpen}
                    handleClose={handleDialogClose}
                    changes={changes}
                    handleRefresh={handleRefresh}
                />
            </Grid>
        </>
    );
}

export default UpdateNotification;
